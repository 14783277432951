<script setup lang="ts">
const { t } = useT();

const props = withDefaults(
	defineProps<{
		type: "popper" | "page";
	}>(),
	{ type: "popper" }
);

const { gamesWithWeekGame } = useHomePage();
const { dataGameSearch, gameName, isLoading, isResponse, clearSearch } = useGameSearch();

const games = computed(() => {
	if (!gameName.value) {
		return [];
	}

	if (props.type === "page") {
		return dataGameSearch?.value?.games ?? [];
	}

	return dataGameSearch?.value?.games?.slice(0, 6) ?? [];
});
const gamesWithWeek = computed(() => gamesWithWeekGame?.value?.slice(0, 3));
const showAllGames = computed(() => dataGameSearch.value?.games && dataGameSearch.value?.games?.length > 6);
</script>
<template>
	<div :class="['wrapper-result', type]">
		<OSearchGamesLoading v-if="isLoading" />
		<OSearchGamesWrapper
			v-else-if="type === 'popper' && games?.length"
			:games="games"
			:title="t('Search results:')"
			category="all-games"
			hide-favorites
			:show-all="showAllGames"
			@clear-search="clearSearch"
		/>
		<OHomeGames
			v-else-if="dataGameSearch?.games?.length"
			:games="dataGameSearch?.games"
			:title="t('Search results:')"
			:rows-per-slide="1"
			:isShowAll="false"
			class="row-slider"
		/>
		<OSearchGamesNoResult v-else-if="isResponse" />
		<OSearchGamesWrapper
			v-if="type === 'popper' && gamesWithWeek.length"
			:games="gamesWithWeek"
			:title="t('Must try')"
			icon="home/must-try"
			category="popular-games"
			showAll
			hide-favorites
			@clear-search="clearSearch"
		/>
		<OHomeGames
			v-else-if="gamesWithWeek.length"
			:games="gamesWithWeekGame"
			:title="t('Must try')"
			icon="home/must-try"
			:rows-per-slide="1"
			:isShowAll="false"
			class="row-slider slider-2"
		/>
	</div>
</template>

<style lang="scss" scoped>
.wrapper-result {
	display: flex;
	flex-direction: column;
	overflow: hidden;

	&.popper {
		gap: 16px;
		width: 296px;
	}

	&.page {
		width: 100%;

		@include media-breakpoint-down(md) {
			padding: 0 16px;
		}

		&:deep(.result-games),
		&:deep(.box-loading) {
			width: 100%;
			gap: 24px;

			.title {
				font-size: 38px;

				@include media-breakpoint-down(md) {
					font-size: 24px;
				}
			}

			.box-games {
				display: flex;
				flex-wrap: wrap;
				gap: 12px;
			}

			.skeleton-wrapper {
				width: 179px;
				height: 200px;

				@include media-breakpoint-down(md) {
					width: 124px;
					height: 139px;
				}
			}

			.game {
				width: 179px;
				height: 200px;

				@include media-breakpoint-down(md) {
					width: 108px;
					height: 120px;
				}
			}
		}

		&:deep(.box-no-result) {
			.title {
				font-size: 38px;
				margin-bottom: 24px;

				@include media-breakpoint-down(md) {
					font-size: 24px;
					margin-bottom: 16px;
				}

				span {
					text-transform: none;
				}
			}

			.txt {
				text-align: center;
				font-size: 12px;
				font-weight: 600;
				color: var(--neutral-500);
			}

			.subtxt {
				display: block;
				margin-bottom: 8px;
			}
		}
	}
}

.row-slider {
	padding: 0;

	&.slider-2 {
		padding: 32px 0 0;
	}

	&:deep(.title) {
		flex-direction: row-reverse;

		span {
			text-transform: none;
		}
	}
}
</style>
